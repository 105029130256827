import React from 'react';
import cntl from 'cntl';
import { graphql, Link, PageProps } from 'gatsby';
import Footer from '../components/landing/sections/footer';
import brandSVG from '../assets/brand.svg';
import useSiteMetadata from '../hooks/useSiteMetadata';
import { NotFoundPageQuery } from '../@types';

const containerClassName = cntl`
  min-h-screen
  pt-16
  pb-0
  flex
  flex-col
  bg-white
`;

const mainContentClassName = cntl`
  flex-grow
  flex
  flex-col
  justify-center
  max-w-7xl
  w-full
  mx-auto
  px-4
  sm:px-6
  lg:px-8
`;

const logoWrapperClassName = cntl`
  flex-shrink-0
  flex
  justify-center
`;

const logoImageClassName = cntl`
  h-14
  w-auto
`;

const errorNumberClassName = cntl`
  text-sm
  font-semibold
  text-primary-600
  uppercase
  tracking-wide
`;

const errorTitleClassName = cntl`
  mt-2
  text-4xl
  font-extrabold
  text-gray-900
  tracking-tight
  sm:text-5xl
`;

const errorDescriptionClassName = cntl`
  mt-2
  text-base
  text-gray-500
`;

const homeLinkClassName = cntl`
  text-base
  font-medium
  text-primary-600
  hover:text-primary-500
`;

const NotFound: React.FC<PageProps<NotFoundPageQuery>> = ({ data }) => {
  const { site } = useSiteMetadata();

  return (
    <div className={containerClassName}>
      <main className={mainContentClassName}>
        <div className={logoWrapperClassName}>
          <Link to="/" className="inline-flex">
            <span className="sr-only">{site.siteMetadata.title}</span>
            <img
              className={logoImageClassName}
              src={brandSVG}
              alt={site.siteMetadata.title}
            />
          </Link>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className={errorNumberClassName}>404 error</p>
            <h1 className={errorTitleClassName}>Page not found.</h1>
            <p className={errorDescriptionClassName}>
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            <div className="mt-6">
              <Link to="/" className={homeLinkClassName}>
                Go back home<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <Footer serviceAreas={data.serviceAreas} />
    </div>
  );
};

export const query = graphql`
  query NotFoundPageQuery {
    serviceAreas: allContentfulServiceArea {
      nodes {
        contentful_id
        city
        county
        state
      }
    }
  }
`;

export default NotFound;
