import { NodeList, ServiceArea } from './@types';

export type CountyMap = { [county: string]: string[][] };

export function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ');
}

export function getServiceAreaLinksGroupedByCounties(
  serviceAreas: NodeList<ServiceArea>
): CountyMap {
  const countyMap: CountyMap = {};

  serviceAreas.nodes.forEach(({ city, county, state }) => {
    // Transform properties
    const finalCounty = county.trim();
    const finalCity = city.replace(/\s+/g, '-').toLowerCase();
    const finalState = state.toLowerCase();

    // All custom pages follow the same pattern
    const path = `/mobile-detailing-service-areas/${finalCity}-${finalState}`;

    if (typeof countyMap[finalCounty] === 'undefined') {
      countyMap[finalCounty] = [[path, city]];
    } else {
      countyMap[finalCounty].push([path, city]);
    }
  });

  return countyMap;
}
