import { graphql, useStaticQuery } from 'gatsby';
import { SiteData } from '../@types';

interface SiteMetadataQuery {
  site: SiteData;
}

export default function useSiteMetadata(): SiteMetadataQuery {
  const data = useStaticQuery<SiteMetadataQuery>(
    graphql`
      query SiteMetadataQuery {
        site {
          siteMetadata {
            title
            siteUrl
            description
            googleMyBusinessUrl
            facebookPageUrl
            instagramUrl
          }
        }
      }
    `
  );

  return data;
}
